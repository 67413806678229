<template>
   <div class="box-wrapper">
      <div class="card-carousel-wrapper">
         <div class="card-carousel--nav__left" 
            @click="moveCarousel(-1)"
            :disabled="atHeadOfList"
         >
         </div>
         <div class="card-carousel">
            <div class="card-carousel--overflow-container">
                  <div class="card-carousel-cards" 
                     :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}"
                  >
                     <div class="card-carousel--card" 
                        v-for="(item, index) in items" 
                        :key="index"
                     >
                     
                     <a href="#" >
                        <v-icon
                           color="rgb(202, 83, 202)"
                           data-aos="zoom-in"
                           data-aos-duration="1450"
                           :data-aos-delay="index*600"
                        >
                            {{ item.icon }}
                        </v-icon>
                     
                        <div class="card-carousel--card--footer">
                           <h4> {{ item.title }} </h4>
                              <p>
                              {{ item.name }}
                              </p>
                        </div>
                     </a>
                     </div>
                   
                  </div>
            </div>
         </div>

         <div class="card-carousel--nav__right" 
            @click="moveCarousel(1)" 
            :disabled="atEndOfList"
         >
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Carousel',
   props: {
   },
   computed: {
      atEndOfList() {
         return this.currentOffset <= (this.paginationFactor * -1) * (this.items.length - this.windowSize);
      },
      atHeadOfList() {
         return this.currentOffset === 0;
      },
      items () {
         return [
            { 
               name: this.$i18n.t('carouseltext.analytics'),
               title: this.$i18n.t('carouseltitle.analytics'),
               icon: "mdi-google-analytics"
            },
            { 
               
               name: this.$i18n.t('carouseltext.ecommerce'),
               title: this.$i18n.t('carouseltitle.ecommerce'),
               icon: "mdi-flower-tulip-outline"
            },
            { 
               name: this.$i18n.t('carouseltext.diseño'),
               title: this.$i18n.t('carouseltitle.diseño'),
               icon: "mdi-dialpad"
            },
            {  
               name: this.$i18n.t('carouseltext.content'),
               title: this.$i18n.t('carouseltitle.content'),
               icon: "mdi-head-cog-outline"
            },
            { 
               name: this.$i18n.t('carouseltext.audition'),
               title: this.$i18n.t('carouseltitle.audition'),
               icon: "mdi-iframe"
            },
            { 
               name: this.$i18n.t('carouseltext.mobile'),
               title: this.$i18n.t('carouseltitle.mobile'),
               icon: "mdi-cellphone-link"
            },
         ]
      }
      
   },
   data () {
      return {
         currentOffset: 0,
         windowSize: 1,
         paginationFactor: 290,
         colors: [
            '#333333',
            '#242424',
            '#667833',
            'red lighten-1',
            'deep-purple accent-4',
         ],
         slides: [
            'First',
            'Second',
            'Third',
            'Fourth',
            'Fifth',
         ],

      }
   },
   methods: {
      moveCarousel(direction) {
      // Find a more elegant way to express the :style. consider using props to make it truly generic
         if (direction === 1 && !this.atEndOfList) {
               this.currentOffset -= this.paginationFactor;
         } else if (direction === -1 && !this.atHeadOfList) {
               this.currentOffset += this.paginationFactor;
         }
      },
  /*     setLocale(locale){
         this.$i18n.locale = locale
      }, */
   },
/*    computed: { */
/*       items () {
         return this.$store.state.carousel
      }, */

/*    }, */
}

</script>

<style scoped lang="scss">

.image-carro {
   width: 200px;
}

.final-row {
   margin: 0;
   padding: 70px 0;
   background-color: #eee;
}

   $vue-navy: #2c3e50;
   $vue-navy-light: #3a5169;
   $vue-teal: #1d1a1a;
   $vue-teal-light: #1d1a1a;
   $gray: #666a73;
   $light-gray: #f8f8f8;

.box-wrapper {
   text-align: center;
   position: relative;
   flex: 100 1 1;
   color: white;
   width: 100%;
}

.card-carousel-wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 20px 0 40px;
   color: grey;
}

.card-carousel {
   display: flex;
   justify-content: center;
   width: 1024px;

   &--overflow-container {
      overflow: hidden; 
      margin-left: -25px;
   }

   &--nav__left,
   &--nav__right {
      display: inline-block;
      //width: 45px;
     // height: 15px;
      padding: 10px;
      box-sizing: border-box;
      border-top: 6px solid $vue-teal;
      border-right: 6px solid $vue-teal;
      cursor: pointer;
      margin: 0 20px;
      transition: transform 1150ms linear;

      &[disabled] {
            opacity: 0.2;
            border-color: black;
      }
   }

   &--nav__left {
      transform: rotate(-135deg);
      &:active {
            transform: rotate(-135deg) scale(0.9);
      }
   }

   &--nav__right {
            transform: rotate(45deg);
      &:active {
            transform: rotate(45deg) scale(0.9);
      }
   }
}

.card-carousel-cards {
   display: flex;
   transition: transform 1000ms ease-in-out;
   transform: translatex(0px);

   .card-carousel--card {
      margin: 0 10px;
      cursor: pointer;
      box-shadow: 0 4px 15px 0 rgba(40,44,53,.06), 0 2px 2px 0 rgba(40,44,53,.08);
      background-color: #fff;
      border-radius: 4px;
      z-index: 3;
      margin-bottom: 2px;
      
      &:first-child {
         margin-left: 0;
      }
      &:last-child {
         margin-right: 0;
      }

      a i {
         font-size: 56px !important;
         margin-bottom: 15px;
      }

      img {
         vertical-align: bottom;
         border-top-left-radius: 4px;
         border-top-right-radius: 4px;
         transition: opacity 150ms linear;
         user-select: none;
         &:hover {
            opacity: 0.5;
         }
      }

      &--footer {
        // border-top: 0;
        // padding: 7px 30px;
        // width: 210px;
        // box-sizing: content-box;

         h4 {
            height: 45px;
            color: #058baa;
            font-size: .87em !important;
            text-transform: uppercase;
         }
      
         p {
            padding: 3px 0;
            margin: 0;
            font-size: .78em;
            font-weight: 500;
            color: $vue-navy;
            text-align: center;
            user-select: none;
            height: 170px;

         
            &.tag {
               font-size: 11px;
               font-weight: 300;
               padding: 4px;
               background: rgba(40,44,53,.06);
               display: inline-block;
               position: relative;
               margin-left: 4px;
               color: $gray;
            
               &:before {
                  content: "";
                  float:left;
                  position:absolute;
                  top:0;
                  left: -12px;
                  width:0;
                  height:0;
                  border-color:transparent rgba(40,44,53,.06) transparent transparent;
                  border-style:solid;
                  border-width:8px 12px 12px 0;
               }

               &.secondary {
                  margin-left: 0;
                  border-left: 1.45px dashed white;
                  &:before {
                     display: none !important;
                  }
               }
               
               &:after {
                  content:"";
                  position:absolute;
                  top:8px;
                  left:-3px;
                  float:left;
                  width:4px;
                  height:4px;
                  border-radius: 2px;
                  background: white;
                  box-shadow:-0px -0px 0px #004977;
               }
            }
         }
      }
   }
}

</style>